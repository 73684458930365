import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Checkbox } from '@material-ui/core';

import Male from '../../assets/img/Male.png';
import Female from '../../assets/img/Female.png';
import HistoryIcon from '../../assets/img/HistoryIcon.png';

import { setOrderStatusFromTurn } from '../../redux/orderInProgress/action';
import { getPreviewOrderData, addName, closeTurnItemPreview, setOpenAddPhone, takeOrder, setOpenCustomerHistory } from '../../redux/order/action';
import { displayPhone } from '../../assets/js/functions';
import AddPhoneWindow from '../AddPhoneWindow/AddPhoneWindow';

import CustomerHistory from '../CustomerHistory/CustomerHistory';
import ResetBalansModal from './ResetBalansModal';
import './TurnItemPreview.scss';

const TurnItemPreview = (props) => {
	useEffect(() => {
		props.getPreviewOrderData(props.orderNum);
	}, [])

	const [previewOrderData, setPreviewOrderData] = useState({});
	const [customerName, setCustomerName] = useState('');
	const [newCustomerName, setNewCustomerName] = useState('');
	const [alertCustomerName, setAlertCustomerName] = useState(false);

	const addName = (e) => {
		e.preventDefault();

		if (newCustomerName !== '') {
			props.addName(newCustomerName)
				.then(() => {
					setNewCustomerName('')
					setCustomerName(newCustomerName)
				});
		}
	}

	const setNewCustomerNameInState = (event) => {
		setNewCustomerName(event.target.value)
	}

	const changeCustomerName = (event) => {
		setCustomerName(event.target.value)
	}

	const takeOrder = () => {
		if (+props.previewOrderData.customer_phone === 0) {
			props.takeOrder('Без имени', props.history)
				.then(() => {
					props.history.push('/order_in_progress')
				})
		} else {
			if (customerName) {
				props.takeOrder(customerName, props.history)
					.then(() => {
						props.history.push('/order_in_progress')
					})
			} else {
				setAlertCustomerName(true);
				setTimeout(() => {
					setAlertCustomerName(false);
				}, 1500)
			}
		}
	}

	const setOrderStatusFromTurn = (event) => {
		props.setOrderStatusFromTurn(event.target.dataset.status)
	}

	if (Object.keys(props.previewOrderData).length === 0) {
		return '';
	}

	let bgStyle = {};
	if (+props.previewOrderData.customer_phone !== 0) {
		bgStyle = props.previewOrderData.customer.gender === 'мужской' ? { backgroundImage: `url(${Male})` } : { backgroundImage: `url(${Female})` }
	}

	return (
		<div class="turn_item_preview">
			<div className="modal_window" style={bgStyle}>
				<h2>Клиент {" "}
					{props.previewOrderData.customer_name ? 
						<span>{props.previewOrderData.customer_name}</span> :
						<span>№ {props.previewOrderData.turn_num}</span>
					}
				</h2>

				<div className="phone_block">
					{+props.previewOrderData.customer_phone ? (
						<>
							<div className="phone"> <img className="historyIcon" onClick={() => props.setOpenCustomerHistory(1)} src={HistoryIcon} alt="HistoryIcon" /> {displayPhone(props.previewOrderData.customer_phone.split(''), '-')}</div>
							<div className="age">{props.previewOrderData.customer.age}</div>

						</>
					) :
						(
							<>
								<div className="phone">Заказ без телефона</div>
								<button class="addPhone" onClick={() => props.setOpenAddPhone(1)}>+</button>
							</>
						)}
				</div>


				<div class="serv_list">
					{props.previewOrderData.services.map((value) => <><div class="serv_name">{value.name}</div><div class="serv_sum">{value.price}</div></>)}
					<div className="serv_name" style={{ fontWeight: 'bold', marginTop: '25px', fontSize: '26px' }}>Итоговая сумма:</div>
					<div className="serv_sum cr" style={{ marginTop: '25px', fontSize: '26px' }}>{props.previewOrderData.all_sum}</div>
				</div>

				{+props.previewOrderData.customer_phone ?
					<Grid container style={{ marginBottom: 32, fontWeight: 'bold' }}>
						<Grid item xs='6' justifyContent="center" alignItems="center" style={{display: 'flex'}}>
							M
							{[0, 0, 0].map((item, index) => {
								const checked = props.previewOrderData.customer.balance >= index + 1
								return (
									<Checkbox
										checked={checked}
										color="primary"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
								)
							})}
						</Grid>
						<Grid item xs='6' justifyContent="center" alignItems="center" style={{display: 'flex'}}>
							Ж
							{[0, 0, 0].map((item, index) => {
								const checked = props.previewOrderData.customer.creative_haircut_balance >= index + 1
								return (
									<Checkbox
										checked={checked}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								)
							})}
						</Grid>
					</Grid>
					: ''}

				{+props.previewOrderData.customer_phone ?
					(
						<div className="client_name_block">
							<div class="name_list">
								{
									props.previewOrderData.customer.names.map((item) => {
										return (
											<label>
												<input onChange={changeCustomerName} type="radio" name="customer_name" value={item.name} id="" checked={item.name === customerName} />
												<div className="name" >{item.name}</div>
											</label>
										)
									})
								}
								<label>
									<input onChange={changeCustomerName} type="radio" name="customer_name" value='Без имени' id="" />
									<div className="name">Продолжить без имени</div>
								</label>

							</div>
							<form class="new_name" onSubmit={addName}>
								<input style={alertCustomerName ? { boxShadow: 'inset 0px 0px 13.3px 3px rgb(255 0 0)' } : {}} type="text" value={newCustomerName} onChange={setNewCustomerNameInState} placeholder="ВВЕДИТЕ ИМЯ" />
								<button type='submit' class="plus">+</button>
							</form>
						</div>
					) : ''
				}


				<div className="btn_block">
					<div onClick={takeOrder} class="yes">Взять заказ</div>
					<div className="wrapper">
						<div data-status='44' onClick={setOrderStatusFromTurn} class="any_client">Клиент отсутствует</div>
						<div data-status='45' onClick={setOrderStatusFromTurn} class="error">Ошибка</div>
					</div>
					<div onClick={props.closeTurnItemPreview} class="no">Вернуться к очереди</div>
				</div>

				<AddPhoneWindow />
				<CustomerHistory />
			</div>

			<ResetBalansModal
				open={props.previewOrderData.canResetBalanse}
				orderId={props.previewOrderData.id}
				phone={props.previewOrderData.customer_phone}
				balance={props.previewOrderData.customer?.balance}
				creative_haircut_balance={props.previewOrderData.customer?.creative_haircut_balance}
				getOrderData={() => props.getPreviewOrderData(props.previewOrderData.id)}
				service={props.previewOrderData?.services.filter(({ service_name_id }) => +service_name_id === 2)}
			/>

		</div>
	);
}

const mapStateToProps = ({ orderReducer }) => {
	return {
		previewOrderData: orderReducer.previewOrderData,
	}
}

const mapDispatchToProps = {
	getPreviewOrderData,
	addName,

	closeTurnItemPreview,
	setOpenAddPhone,

	takeOrder,
	setOrderStatusFromTurn,

	setOpenCustomerHistory
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TurnItemPreview))
